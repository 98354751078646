import React, { useState } from 'react';
import Layout from '../components/Layout';
import { StateConsumer } from '../context/StateContext'
import userAv from '../assets/global-images/user/userAv.jpg';
import LoaderCenterHorizontal from '../components/Commons/LoaderCenterHorizontal';
import GatsbyLink from 'gatsby-link';
import { Flex, Box } from 'rebass'

const Account = (props) => {

  const loginUrl = 'https://api.source.xyz/user/login/sso?returnTo=';

  let finalLoginUrl = loginUrl + 'https://www.formlight.com';

  const doNavigate = () => {
    window.location.href = finalLoginUrl
  }

  return (
    <Layout>
      <StateConsumer>
        {({ myBock, myOrders }) => {
          const { isAuthenticated, name, email } = myBock;

          if(isAuthenticated === true){

            return (
              <Box mt={[180]} mb={[120]}>

              <div className="ccnProfileBox ccnProfileBoxMain ccnCard">
                <div class="profileImg"><img src={userAv} /></div>
                <h1>{name}</h1>
                <div className="profileMeta">
                  <span>{email}</span> <span>FormLight User</span>
                </div>
              </div>
              <div className="ccnProfileBox ccnCard">
                <h1>Order History</h1>
                  {myOrders && myOrders.length > 0 ?
                    myOrders.map((order, key) => {

                    let { total, subtotal, created, status_url, items, note, id } = order;

                    total = '$' + total + ' USD';
                    subtotal = '$' + subtotal + ' USD';

                    return (
                      <>
                        <div className="orderHistoryItem">
                          <div className="ohi_top">
                            <span className="ohi_top_order"><i class="dlicon shopping_bag-09"></i> #{id}</span>
                            <span className="ohi_top_date"><i class="dlicon ui-2_time-clock"></i> {created}</span>
                          </div>
                          <div className="ohi_items row">
                            {(items && items.length > 0) &&
                              items.map((item, key) => {
                                return (
                                  <div className="col-12 p-0">
                                    <div className="ohi_item row">
                                      <div className="ohi_item_1 col-md-9">
                                      <span class="ohi_itemName">{item.name}</span>
                                      <span class="ohi_itemSku">{item.sku}</span>
                                    </div>
                                    <div className="ohi_item_2 col-md-3">
                                      <span class="ohi_itemPrice"><em>Price:</em> ${item.price}</span>
                                      <span class="ohi_itemQuantity"><em>Quantity:</em> {item.quantity}</span>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                          <div class="ohi_summary">
                            <div class="row">
                              <div class="col-md-9">
                                <div class="ohi_summary_subtotal">
                                  <em>Subtotal:</em> {subtotal} (Excluding tax)
                                </div>
                                <div class="ohi_summary_total">
                                  <em>Total:</em> {total}
                                </div>
                              </div>
                              <div class="col-md-3">
                                <a target="_blank" href={status_url} class="reg-btn">Status</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }
                ) : (
                  <>You haven't placed any orders yet. <GatsbyLink to={"/catalog"}><b>Browse products</b></GatsbyLink> or <GatsbyLink to={"/create"}><b>create your own</b></GatsbyLink>.<br/><br/></>
                )}
              </div>
            </Box>
          )
        }
        if(isAuthenticated === false){
          doNavigate()
          return <LoaderCenterHorizontal />
        }
          return <LoaderCenterHorizontal />;
        }}
      </StateConsumer>
    </Layout>
  )
}

export default Account;
